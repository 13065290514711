$light-grey-font-color: #383838;
.logo_sec {
    padding: 150px 9%;
    background: #f4f8fb;
    .big_black_headingMb {
        font-weight: 800;
        font-size: 50px;
        line-height: 60px;
        letter-spacing: -0.02em;
        //text-decoration-line: underline;
        color: #0d1317;
        font-family: Gilroy !important;
    }
}
.card_accordian_sec {
    padding: 100px 9%;
    .first_para {
        font-size: 24px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $light-grey-font-color;
    }
    .small_cards {
        margin-top: 70px;
        .card {
            height: 100%;
            background: #ffffff;
            border: 1px solid #f4f8fb;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 30px;
            .title {
                text-align: center;
                font-weight: 800;
                font-size: 24px;
                letter-spacing: -0.02em;
                color: #0d1317;
                height: 50%;
            }
            .know_more {
                height: 50%;
                font-weight: 800;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #2469a0;
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }
}
.accord_wrap {
    margin-top: 50px;
    .accordianSection {
        cursor: pointer;
        padding: 30px 0px;
        border-bottom: 1px solid #d3e1ec;
        .active_Accordian {
            display: block;
        }
        .close_Accordian {
            display: none;
        }
        .accordian_title {
            display: flex;
            justify-content: space-between;
            .accord_title {
                font-weight: 800;
                font-size: 22px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
            }
        }
        .active_title {
            display: flex;
            justify-content: space-between;
            .accord_title {
                font-weight: 800;
                font-size: 22px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #ffa800;
            }
        }
        .chevron_icon {
            margin-top: 10px;
            .blue_text {
                font-weight: 800;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #2469a0;
                margin-right: 10px;
            }
            .orange_text {
                font-weight: 800;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #ffa800;
                margin-right: 10px;
            }
        }
        .details {
            margin-top: 10px;
            .text {
                font-weight: normal;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -0.02em;
                color: #0d1317;
                display: inline-block;
                margin-left: 12px;
            }
            .circle {
                width: 10px;
                height: 10px;
                background: #3dd6af;
                border-radius: 50%;
                display: inline-block;
            }
        }
        .para {
            p {
                letter-spacing: -0.02em;
                color: $light-grey-font-color;
                font-size: 20px;
                line-height: 30px;
                text-align: justify;
            }
        }
    }
}
.big_card_wrap {
    padding: 150px 9%;
    .heading {
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $light-grey-font-color;
        width: 70%;
    }
    .headingMb {
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $light-grey-font-color;
    }
    .cards_wrap {
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .cards {
            background: #ffffff;
            border: 1px solid #f4f8fb;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 40px;
            width: 30%;
            margin-right: 3.33%;
            margin-bottom: 3.33%;
            .heading_black {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
            }
            .description {
                margin-top: 10px;
                .circle {
                    width: 10px;
                    height: 10px;
                    background: #3dd6af;
                    border-radius: 50%;
                    display: inline-block;
                }
                .text {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.02em;
                    color: $light-grey-font-color;
                    display: inline-block;
                    margin-left: 12px;
                }
            }
        }
    }
    .cards_wrap_mb {
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .cards {
            background: #ffffff;
            border: 1px solid #f4f8fb;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 40px;
            width: 100%;
            margin-bottom: 3.33%;
            .heading_black {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
            }
            .description {
                margin-top: 10px;
                .circle {
                    width: 10px;
                    height: 10px;
                    background: #3dd6af;
                    border-radius: 50%;
                    display: inline-block;
                }
                .text {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: -0.02em;
                    color: $light-grey-font-color;
                    display: inline-block;
                    margin-left: 8px;
                }
            }
        }
    }
}
.carousel_Cloudwrap {
    background: #f4f8fb;
    padding: 150px 9%;
    .black_heading {
        font-weight: 800;
        font-size: 55px;
        line-height: 56px;
        letter-spacing: -0.02em;
        color: #0d1317;
    }
}
.public_services {
    padding: 150px 9%;
    .img_wrap {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        img {
            width: 100%;
        }
    }
}
