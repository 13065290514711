.mid_bannercontainer{
    .midBanner_wrap{
         height: 620px;
         background-repeat: no-repeat;
         background-size: 100% 620px;
    }
    .midBannerMb_wrap{
        height: 250px;
        background-repeat: no-repeat;
        background-size: 100% 250px;
    }
}

