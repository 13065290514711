.logoslider_sec{
    .logos{
        img{
            width: 80%;
            height: 50px;
        }
    }
    .left_btn{
        cursor: pointer;
        
    }
    .right_btn{
        cursor: pointer;
        
    }
}
@media screen and (max-width: 480px) {
    .btn_img{
        // border: 1px solid red;
        cursor: pointer;
        margin-left: 9em;
        
    }
  }
