.banner_left {
    padding-top: 150px;
    padding-bottom: 100px;
    .banner_subtext {
        margin-top: 30px;
        font-size: 24px;
        line-height: 35px;
        color: #4f87b3;
    }
    .banner_btn {
        width: 238px;
        height: 60px;
        background: #3dd6af;
        border-radius: 100px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        margin-top: 70px;
        border: none;
    }
}
.banner_left_mb {
    padding-bottom: 100px;
    .banner_subtext {
        margin-top: 30px;
        font-size: 24px;
        line-height: 35px;
        color: #4f87b3;
    }
    .banner_btn {
        width: 238px;
        height: 60px;
        background: #3dd6af;
        border-radius: 100px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        margin-top: 70px;
        border: none;
    }
    .big_black_headingMb{
        font-weight: 800;
        font-size: 50px;
        line-height: 60px;
        letter-spacing: -0.02em;
        //text-decoration-line: underline;
        color: #0D1317;
        font-family: Gilroy !important
    }
}
.bannerimg_wrap {
    padding: 90px 0px;
    text-align: end;
    img {
        height: 450px;
    }
}
.bannerimgMb_wrap {
    padding: 90px 0px;
    text-align: center;
    img {
        width: 100%;
        height: 350px;
    }
}
