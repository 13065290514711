.slider_wrap {
    text-align: center;
    .name {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #ffa800;
        margin-top: 20px;
    }
    .company {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #2469a0;
        margin-top: 7px;
    }
    .btn_wrap {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        div {
            margin-left: 16px;
            img{
                cursor: pointer;
            }
        }
        .numbers {
            margin-top: 18px;
            .current {
                font-weight: 800;
                color: #ffa800;
            }
            .total,.divide {
                font-weight: 800;
            }
        }
    }
}
