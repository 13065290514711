.parentPadding {
    padding: 100px 9%;
   // background: #f4f8fb;
    .blog_desc_wrap {
        background: #ffffff;
        border-radius: 20px;
        //box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        //padding: 4%;
        .blog_img{
            width: 100%;
            height: 400px;
            margin-bottom: 16px;
        }
        .big_blog_heading{
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 32px; 
        }
        .blog_heading {
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 22px;
        }
        .blog_para {
            margin-bottom: 20px;
            letter-spacing: -0.02em;
            color: #383838;
            font-size: 18px;
            line-height: 30px;
        }
        .no_blogsWrap {
            width: 50%;
            margin: 0 auto;
            .oops {
                font-size: 55px;
                font-weight: 600;
                color: #2469a0;
                margin-bottom: 16px;
            }
            .no_mesg{
                margin-bottom: 10px;
            }
        }
    }
}
