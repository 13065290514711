.pagenotfound_wrap{
    margin-top: 100px;
    text-align: center;
    button{
        width: 238px;
        height: 60px;
        background: #3dd6af;
        border-radius: 100px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        margin-top: 70px;
        border: none;
    }
}