$light-font-color: #383838;
.clients_wrap {
    background: #f4f8fb;
    padding: 16px 9%;
    .client_img_wrap{
        text-align: center;
        img{
            width: 80%;
        }
    }
}
.middleContentWrap {
    padding-top: 100px;
    margin-bottom: 150px;
    .mid_sec {
        padding: 70px 0px 0px 0px;
        .content_grid {
            position: relative;
        }
        .content_sec {
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .left_img {
            img {
                width: 510px;
                height: 450px;
            }
            text-align: left;
        }
        .left_img_mb{
            img {
                width: 420px;
                height: 450px;
            }
            text-align: center;
        }
        .right_img {
            img {
                width: 510px;
                height: 450px;
            }
            text-align: end;
        }
        .right_img_mb{
            img {
                width: 420px;
                height: 450px;
            }
            text-align: center;
        }
        .title {
            font-weight: 800;
            font-size: 32px;
            line-height: 56px;
            letter-spacing: -0.03em;
            color: #0d1317;
        }
        .desc {
            font-size: 18px;
            line-height: 30px;
            color: $light-font-color;
        }
        .knowMore_btn {
            font-weight: 800;
            font-size: 18px;
            text-decoration-line: underline;
            color: #2469a0;
            cursor: pointer;
        }
        ul {
            line-height: 28px;
            color: $light-font-color;
            padding: 0px;
            list-style-position: inside;
        }
    }
    .mid_secMb{
        padding: 70px 0px 0px 0px;
        .topImg {
            margin-top: 20px;
            img {
                width: 100%;
                height: 350px;
            }
            text-align: center;
        }
        .title {
            font-weight: 800;
            font-size: 32px;
            line-height: 56px;
            letter-spacing: -0.03em;
            color: #0d1317;
        }
        .desc {
            font-size: 18px;
            line-height: 30px;
            color: $light-font-color;
        }
        .knowMore_btn {
            font-weight: 800;
            font-size: 18px;
            text-decoration-line: underline;
            color: #2469a0;
            cursor: pointer;
        }
    }
}
.focusSec {
    padding: 150px 0px;
    .heading {
        padding: 0px 50px;
    }
    .para {
        font-size: 18px;
        line-height: 30px;
        color: $light-font-color;
    }
}
.working_ways {
    padding: 150px 9%;
    background: #2469a0;
    .green_title {
        font-weight: 800;
        font-size: 32px;
        line-height: 56px;
        letter-spacing: -0.03em;
        color: #8be27e;
    }
    .workingpara {
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        width: 85%;
    }
    .working_btn {
        width: 238px;
        height: 60px;
        background: #3dd6af;
        border-radius: 100px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        margin-top: 70px;
        border: none;
    }
}
.carousel_wrap {
    background: #f4f8fb;
    padding: 150px 9%;
    .black_heading {
        font-weight: 800;
        font-size: 55px;
        line-height: 56px;
        letter-spacing: -0.02em;
        color: #0d1317;
    }
}
.slider_sec {
    padding: 150px 9%;
    text-align: center;
    .text {
        font-size: 24px;
        line-height: 50px;
        text-align: center;
        letter-spacing: -0.02em;
        color: $light-font-color;
        margin: 40px 0px 30px 0px;
    }
}

// .Logoslider1{
//     .Logos{
//         img{
//             width: 100%;
//             height: 80%;
//         }
//     }
//     .left_btn{
//         cursor: pointer;
//     }
//     .right_btn{
//         cursor: pointer;
//     }
// }


