$light-grey-font-color: #383838;
.greytextband {
    padding: 150px 9%;
    background: #f4f8fb;
    .gey_para {
        font-size: 24px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $light-grey-font-color;
        margin-top: 70px;
    }
}
.midText_wrap {
    padding: 150px 9% 0px 9%;
    .heading {
       // width: 80%;
    }
    .text {
        margin-top: 70px;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $light-grey-font-color;
    }
    .lines_wrap {
        margin-top: 70px;
        .lines {
            font-weight: 500;
            font-size: 22px;
            line-height: 35px;
            letter-spacing: -0.02em;
            color: #0d1317;
            display: inline-block;
            margin-left: 2%;
            vertical-align: bottom;
        }
        .circle {
            width: 10px;
            height: 10px;
            background: #3dd6af;
            border-radius: 50%;
            display: inline-block;
            vertical-align: super;
        }
    }
}
.card_accordian_career {
    padding: 150px 9%;
    .accord_wrap_career {
        margin-top: 30px;
        .accordianSection {
            cursor: pointer;
            padding: 30px 0px;
            border-bottom: 1px solid #D3E1EC;
            .active_Accordian {
                display: block;
            }
            .close_Accordian {
                display: none;
            }
            .accordian_title {
                display: flex;
                justify-content: space-between;
                .accord_title {
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 35px;
                    letter-spacing: -0.02em;
                    color: #0d1317;
                }
            }
            .active_title {
                display: flex;
                justify-content: space-between;
                .accord_title {
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 35px;
                    letter-spacing: -0.02em;
                    color: #ffa800;
                }
            }
            .chevron_icon {
                margin-top: 10px;
                .blue_text {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #2469a0;
                    margin-right: 10px;
                }
                .orange_text {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #ffa800;
                    margin-right: 10px;
                }
            }
            .details {
                margin-top: 10px;
                .text {
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: -0.02em;
                    color: #0d1317;
                    display: inline-block;
                    margin-left: 12px;
                }
                .circle {
                    width: 10px;
                    height: 10px;
                    background: #3dd6af;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
            .json_content{
                .json_heading{
                    font-size: 22px;
                    font-weight: 800;
                }
                p,li{
                    line-height: 30px;
                    color: $light-grey-font-color;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .lines_wrap {
        .lines {
            font-size: 18px !important;
        }
    }
}