.footerWrap{
    padding: 100px 0px 36px 0px;
    .simplify_img{
        margin-top: 24px;
    }
    .hr_line{
        margin: 50px 0px;
        border: 1px solid #D3E1EC;
    }
    .footer_tabs{
        padding: 0px 0px 20px 0px;
        color: #2469A0;
        font-size: 18px;
        cursor: pointer;
        a{
            text-decoration: none;
            color: #2469A0;
        }
    }
    .social_icon{
        
        div{
            display: inline-block;
            padding-right: 40px;
            cursor: pointer;
        }
    }
    .copyright_sec{
        margin-top: 18px;
        .rights_msg{
            color:#0D1317;
            font-size: 14px;
            padding-left: 10px;
            vertical-align: super;
        }
    }
    .terms{
        margin-top: 18px;
        color:#0D1317;
        font-size: 14px;
        cursor: pointer;
    }
    
}
