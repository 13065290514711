.head_sec {
    position: sticky;
    top: 0;
    background: white;
    z-index: 999;
    .headerWrap {
        display: flex;
        height: 100px;
        left: 0px;
        top: 0px;
        border: 1px solid #d3e1ec;
        .left_sec {
            width: 30%;
            padding: 25px 0px;
            img {
                cursor: pointer;
            }
        }
        .right_sec {
            width: 70%;
            padding: 40px 0px;
            text-align: end;

            .tabs {
                display: inline-block;
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
                color: #2469a0;
                margin-left: 2rem;
                cursor: pointer;
            }
            .active_tabs{
                display: inline-block;
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
                color: #ffa800;
                margin-left: 2rem;
                cursor: pointer;
            }
            .active_tabs:hover{
                .dropdown_wrap {
                    color: #2469a0 !important;
                }
            }
            .tabs:hover {
               // color: #ffa800;
                .dropdown_wrap {
                    color: #2469a0 !important;
                }
            }
            .dropdown_wrap:hover {
                color: #2469a0;
            }
            .dropdown_wrap {
                background: #ffffff;
                height: 215px;
                position: absolute;
                width: 245px;
                margin-top: 41px;
                padding: 30px;
                text-align: start;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                .rows {
                    display: table;
                }
                .icon_wrap {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                }
                .icon_name {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 30px;
                }
            }
        }
        .right_sec_mb {
            padding: 36px 0px;
            text-align: end;
            width: 100%;
            svg {
                height: 30px;
                width: 30px;
                fill: #34699c;
                cursor: pointer;
            }
        }
    }
    .menu_area{
        background: #51bbdf4a;
        padding: 20px 9%;
        .tabs_mb {
            font-weight: 800;
            font-size: 16px;
            line-height: 20px;
            color: #2469a0;
            cursor: pointer;
            padding: 10px 0px;

        }
        .active_tabs_mb{
            font-weight: 800;
            font-size: 16px;
            line-height: 20px;
            color: #ffa800;
            cursor: pointer;
            padding: 10px 0px;
        }
        .tabs_mb:hover{
            color: #ffa800;
            .dropdown_wrap_mb {
                color: #2469a0 !important;
            }
        }
        .dropdown_wrap_mb{
            margin-left: 20px;
            .rows{
                margin-top: 10px;
                .circle{
                    width: 10px;
                    height: 10px;
                    background: #3dd6af;
                    border-radius: 50%;
                    display: inline-block;
                }
                .icon_name{
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .rows:hover{
                color: #ffa800;
            }
        }
    }
}
.header {
    width: 100%;
    background-color: #f1f1f1;
}

.header h2 {
    text-align: center;
}

.progress-container {
    width: 100%;
    height: 0px;
    background: transparent;
}

.progress-bar {
    height: 5px;
    background: #ffa800;
    width: 0%;
}

.content {
    padding: 100px 0;
    margin: 50px auto 0 auto;
    width: 80%;
}
