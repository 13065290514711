$light-grey-font-color: #383838;
.cards_sec {
    padding: 150px 9%;
    background: #f4f8fb;
    .orange_title {
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #ffa800;
        margin-bottom: 20px;
    }
    .cards_wrap {
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .cards {
            background: #ffffff;
            border: 1px solid #f4f8fb;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 50px;
            width: 30%;
            margin-right: 3.33%;
            margin-bottom: 3.33%;
            .heading_black {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
            }
            .description {
                margin-top: 27px;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -0.02em;
                color: $light-grey-font-color;
            }
        }
    }
    .cards_wrap_mb{
        margin-top: 70px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        .cards {
            background: #ffffff;
            border: 1px solid #f4f8fb;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 50px;
            width: 100%;
            margin-bottom: 3.33%;
            .heading_black {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
            }
            .description {
                margin-top: 27px;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -0.02em;
                color: $light-grey-font-color;
            }
        }
    }
}
.lower_text_sec {
    padding: 150px 0px;
    .heading {
        padding: 0px 50px;
    }
    .para {
        font-size: 18px;
        line-height: 30px;
        color: $light-grey-font-color;
    }
    .blue_heading {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-decoration-line: underline;
        color: #2469a0;
        cursor: pointer;
    }
}
