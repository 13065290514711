.carouselMb_sec {
    margin-top: 70px;
    position: relative;
    .carousel_cards {
        height: 487px;
        background: #ffffff;
        border: 1px solid #d3e1ec;
        box-sizing: border-box;
        border-radius: 20px;
        cursor: pointer;
    }
    .middle_carousel_cards {
        height: 487px;
        background: #ffffff;
        border: 1px solid #d3e1ec;
        box-sizing: border-box;
        border-radius: 20px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    .image {
        border-top-right-radius: 20px;
        height: 220px
    }
    .text {
        padding: 30px;
        .heading {
            font-weight: 800;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: -0.02em;
            color: #0d1317;
            overflow-y: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .desc {
            margin-top: 15px;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #5b5b5b;
            overflow-y: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
    .left_btn{
        position: absolute;
        top: 40%;
        left: -8.5%;
        cursor: pointer;
    }
    .right_btn{
        position: absolute;
        top: 40%;
        right: -8.5%;
        cursor: pointer;
    }
}
