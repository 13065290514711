.blogWraper {
    padding: 100px 9%;
    background: #f4f8fb;
}
.blogs_wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 70px;
    .blog_cards {
        height: 487px;
        background: #ffffff;
        border: 1px solid #d3e1ec;
        box-sizing: border-box;
        border-radius: 20px;
        transition: transform 0.2s;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        width: 30%;
        margin-right: 3.33%;
        margin-bottom: 3.33%;
        cursor: pointer;
        .image {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            height: 220px;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .text {
            padding: 30px;
            .heading {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
                font-weight: 800;
                overflow-y: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .desc {
                margin-top: 15px;
                font-weight: normal;
                font-size: 16px;
                line-height: 28px;
                color: #5b5b5b;
                overflow-y: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
    .blog_cards:hover {
        transform: scale(1.05);
    }
}
.blogs_wrap_mb {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-top: 70px;

    .blog_cards {
        height: 487px;
        background: #ffffff;
        border: 1px solid #d3e1ec;
        box-sizing: border-box;
        border-radius: 20px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-bottom: 10%;
        cursor: pointer;
        .image {
            border-top-right-radius: 20px;
            height: 220px;
        }
        .text {
            padding: 20px;
            .heading {
                font-weight: 800;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #0d1317;
                overflow-y: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .desc {
                margin-top: 15px;
                font-weight: normal;
                font-size: 16px;
                line-height: 28px;
                color: #5b5b5b;
                overflow-y: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}
.pagination_wrap {
    text-align: center;
}
.blog_btn_wrap {
    display: flex;
    background: white;
    border-radius: 20px;
    margin-top: 70px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    div {
        width: 50%;
        text-align: center;
        padding: 20px 0px;
        cursor: pointer;
        font-weight: 600;
    }
    #left_btn_blog {
        border-right: 1px solid #d3e1ec;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    #right_btn_blog {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .active_Blog_btn {
        background: #3dd6af;
        color: #ffffff;
    }
}
