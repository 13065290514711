.term_wrap {
    padding: 100px 9%;
    .term_heading {
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 22px;
    }
    .term_para {
        margin-bottom: 20px;
        letter-spacing: -0.02em;
        color: #383838;
        font-size: 20px;
        line-height: 30px;
    }
}
