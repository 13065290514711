.lower_bannercontainer {
    padding: 50px 9%;
    background: #2469a0;

    .left_grid {
        position: relative;
        .leftPart {
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            .green_para {
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                text-decoration-line: underline;
                color: #96e48a;
                cursor: pointer;
            }
        }
        .leftPart_mb{
            .green_para {
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                text-decoration-line: underline;
                color: #96e48a;
                cursor: pointer;
            }
        }
    }
    .img_wraper {
        padding: 30px 0px;
        text-align: end;
        img{
            height: 450px;
        }
    }
    .imgMb_wraper{
        padding: 30px 0px;
        text-align: center;
        img{
            width: 100%;
            height: 350px;
        }
    }
}
