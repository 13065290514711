@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'),url(./fonts/Gilroy/Gilroy.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.parentWraper{
  padding: 0% 9%;
}
.big_black_heading{
  font-weight: 800;
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -0.02em;
  //text-decoration-line: underline;
  color: #0D1317;
  font-family: Gilroy !important
}
.big_white_heading{
  font-weight: 800;
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -0.03em;
  //text-decoration-line: underline;
  color: #FFFFFF;
  font-family: Gilroy !important
}