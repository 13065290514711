.contactUs_sec{
    padding: 0% 9%;
    background: #F4F8FB;
.form_wrap{
    padding: 100px 0px;
    .form_sec{
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;  
        padding: 8%; 
        .white_heading{
            text-align: center;
            padding: 0px 27%;
            letter-spacing: -0.03em;
        }
        .white_heading_mb{
            text-align: center;
        }
        // .css-1wt0ykv::before {
        //     border-bottom: 1px solid #bdd2e2 !important;
        // }
        // .css-1wt0ykv::after {
        //     border-bottom: 1px solid #bdd2e2 !important;
        // }
        // .css-mqt2s5 {
        //     color: #BDD2E2 !important; 
        // }
        // .css-348kf2.Mui-focused{
        //     color: #BDD2E2 !important; 
        // }
        // .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
        //     color: #BDD2E2 !important;
        // } 
        // .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
        //     color: #BDD2E2 !important;
        // }
        // .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
        //     color: #BDD2E2 !important;
        // } 
        // .css-1480iag-MuiInputBase-root-MuiInput-root:before {
        //     border-bottom: 1px solid #BDD2E2;
        // }
        // .css-1480iag-MuiInputBase-root-MuiInput-root:after {
        //     border-bottom: 2px solid #BDD2E2;
        // } 
        // .MuiInput-input {
        //     color: #BDD2E2 !important;
        // }
        .contactUs_btn{
            text-align: center;
            button{
                width: 238px;
                height: 60px;
                background: #3DD6AF;
                border-radius: 100px;
                color: #FFFFFF;
                cursor: pointer;
                font-size: 16px;
                line-height: 19px;
                font-weight: bold;
                margin-top: 70px;
                border: none;
            }
        } 
        .disableBtn{
            text-align: center;
            button{
                width: 238px;
                height: 60px;
                background: #3DD6AF;
                border-radius: 100px;
                color: #FFFFFF;
                cursor: not-allowed;
                font-size: 16px;
                line-height: 19px;
                font-weight: bold;
                margin-top: 70px;
                border: none;
            }
        } 
    }
}
}
.catchUs_sec{
    padding: 70px 9%;
    //background: #F4F8FB;
    .cards{
         //cursor: pointer;
        // position: relative;
        // z-index: 1;
        background: #FFFFFF;
        border: 1px solid #F4F8FB;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 6% 4%;
        div{
            text-align: center;
        }
        .title{
            margin-top: 30px;
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            color: #0D1317;
        }
        .email{
            margin-top: 22px;
            font-size: 16px;
            line-height: 31px;
            color: #2469A0;
            a{
                text-decoration: none;
                color: #2469A0;
            }
        }
        .emailMb{
            margin-top: 22px;
            font-size: 16px;
            line-height: 31px;
            color: #2469A0; 
            a{
                text-decoration: none;
                color: #2469A0;
            }
        }
    }
    // .cards:after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     transform: scaleY(0);
    //     transform-origin: bottom center;
    //     background: #afebff;
    //     z-index: -1;
    //     transition: transform 0.8s;
    //     border-radius: 20px;
    // }
    // .cards:hover::after {
    //     transform: scaleY(1);
    // }
}
.below_Sec{
    background: #2469A0;
    padding: 100px 0px;
    text-align: center;
    .social_media{
        margin-top: 70px;
        div{
            display: inline-block;
            margin-right: 50px;
            cursor: pointer;
            a{
                color: #96E48A;
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                text-decoration: none;
            }
        }
    }
}
.locationIcon{
    svg{
    fill: red;
    width: 40px;
    height: 40px;
    }
}

.Map{
    font-size: 3rem;
    //padding-left: 4em;
    font-weight: 800;
    text-align: center;
}
.Map2{
    padding-top: 3em;
    font-size: 3rem;
    //padding-left: 4em;
    font-weight: 800;
    text-align: center;
}